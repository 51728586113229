import React from 'react';
import { Spin } from 'antd';

const LoadPage = () => {

    return (
        <div className='loading-page'>
            <Spin 
                size="small"
            />
        </div>
    )

}

export default LoadPage;