import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoadPage from './Routes/loadPage';

import Private from './Routes/private';

//paginas
const Login = lazy(() => import('./pages/Login'));
const Home = lazy(() => import('./pages/Home'));
const Config = lazy(() => import('./pages/Config'));
const SolicitCat = lazy(() => import('./pages/SolicitCat'));
const SolicitAprov = lazy(() => import('./pages/SolicitAprov'));
const NotFound = lazy(() => import('./pages/NotFound'));
const MySolicHist = lazy(() => import('./pages/MySolicHist'));
const MyAprovHist = lazy(() => import('./pages/MyAprovHist'));
const Catering = lazy(() => import('./pages/Catering'));
const Reportes = lazy(() => import('./pages/Reportes'));

const Rotas = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<LoadPage />}>
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route path='/home'
                        element={
                            <Private redirectTo='/'>
                                <Home />
                            </Private>
                        }
                    />
                    <Route path='/config'
                        element={
                            <Private redirectTo='/'>
                                <Config />
                            </Private>
                        }
                    />
                    <Route path='/solic'
                        element={
                            <Private redirectTo='/'>
                                <SolicitCat />
                            </Private>
                        }
                    />
                    <Route path='/aprovsolic'
                        element={
                            <Private redirectTo='/'>
                                <SolicitAprov />
                            </Private>
                        }
                    />
                    <Route path='/mysolic'
                        element={
                            <Private redirectTo='/'>
                                <MySolicHist />
                            </Private>
                        }
                    />
                    <Route path='/myaprov'
                        element={
                            <Private redirectTo='/'>
                                <MyAprovHist />
                            </Private>
                        }
                    />
                    <Route path='/catering'
                        element={
                            <Private redirectTo='/'>
                                <Catering />
                            </Private>
                        }
                    />
                    <Route path='/reportes'
                        element={
                            <Private redirectTo='/'>
                                <Reportes />
                            </Private>
                        }
                    />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default Rotas;