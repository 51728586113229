import {
    message
} from 'antd';
import { apiAws } from './../aws/api';
import { Auth, Amplify } from 'aws-amplify';

import confAmplify from './../aws/configureAmplify';

Amplify.configure(confAmplify);

export const getUserlogado = async (user, setValido) => {

    if(user?.signInUserSession === undefined || user?.attributes === undefined) {
        Auth.signOut();
        setValido(false);
        return;
    }

    const { signInUserSession, attributes } = user;

    const token = signInUserSession.idToken.jwtToken;
    const tokenValid = signInUserSession.accessToken.jwtToken;
    const { email } = attributes;

    await apiAws.get(`getusuar?tokenValid=${tokenValid}&&email=${email}`, {
        headers: {
            'Tokenuser': token
        }
    }).then((resp) => {
        const dadosUser = resp.data.retorno;
        localStorage.setItem('usacin', JSON.stringify(dadosUser));
        if(dadosUser?.usuario?.Item?.ativo === false) {
            message.error({
                content: 'Usuario desactivado en la base de datos.'
            })
            Auth.signOut();
            return setValido(false);
        }
        setValido(true);
    })
        .catch((err) => {
            if (!!err.response && err.response.status === 423) {
                message.error({
                    content: 'Usuario no registrado en la base de datos.'
                })
            } else {
                message.error({
                    content: 'Error al acceder a los datos del portal.'
                })
            }
            Auth.signOut();
            setValido(false);
            //setValido(true);
        })

}