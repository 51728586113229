import React, { useEffect, useCallback, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Auth, Amplify } from 'aws-amplify';

import LoadPage from './loadPage';
import confAmplify from './../aws/configureAmplify';
import { getUserlogado } from './buscaUser';

Amplify.configure(confAmplify);

const PrivateRout = ({ children, redirectTo }) => {

    const [valido, setValido] = useState('');
    let location = useLocation();


    const valor = useCallback(async () => {

        return await Auth.currentAuthenticatedUser()
            .then(
                user => {
                    getUserlogado(user, setValido);
                }
            )
            .catch(
                err => {
                    localStorage.removeItem('usacin');
                    setValido(false);
                }
            )

    }, [])

    useEffect(() => {
        valor()
    }, [valor])

    return valido === '' ? <LoadPage /> : valido ? children : <Navigate to={redirectTo} state={{ from: location}} replace/>

}

export default PrivateRout;