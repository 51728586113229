import React from 'react';
import Rotas from './routes';

const App = () => {
  return (
    <Rotas/>
  );
}

export default App;
